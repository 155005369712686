import React from 'react';
import { bool } from 'prop-types';
import { translate } from 'instances/i18n';
import Link from 'components/Link';
import PrivacyLink from 'containers/PrivacyLink';

export default function PaymentFooter({ showLogout }) {
  return (
    <div className="dialogue-payment-footer">
      <Link
        className="dialogue-payment-footer-link"
        href="/about/termsandconditions"
        target="_blank"
      >
        {translate('terms_and_conditions')}
      </Link>
      {'\u00a0\u00a0•\u00a0\u00a0'}
      <PrivacyLink className="dialogue-payment-footer-link" target="_blank">
        {translate('privacy_statement')}
      </PrivacyLink>
      {showLogout && (
        <span>
          {'\u00a0\u00a0•\u00a0\u00a0'}
          <Link className="dialogue-payment-footer-link" href="/logout" target="_blank">
            {translate('footer.links.logout.label')}
          </Link>
        </span>
      )}
    </div>
  );
}

PaymentFooter.propTypes = {
  showLogout: bool,
};

PaymentFooter.defaultProps = {
  showLogout: false,
};
