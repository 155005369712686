import React from 'react';
import Auth from 'controllers/auth';
import Analytics from 'instances/analytics';
import PaymentActions from 'actions/PaymentActions';
import TopUpActions from 'actions/TopUpActions';
import OutOfBalanceDialogContainer from 'modules/payment/containers/OutOfBalanceDialogContainer';
import PaymentResultDialogue from 'components/dialogues/PaymentResult';
import PaymentRecurringDialogue from 'components/dialogues/PaymentRecurring';
import TopUpContainer from 'modules/payment/TopUpContainer';
import Cookies from 'cookies-js';
import { history } from 'byebye';

function getPaymentCookie() {
  try {
    const payment = Cookies.get('paymentRequest');
    if (payment) {
      return JSON.parse(payment);
    }
    return {};
  } catch (err) {
    return {};
  }
}

function clearPaymentCookie() {
  Cookies.expire('paymentRequest');
}

function onCloseSuccess(returnUrl) {
  return () => {
    const paymentCookie = getPaymentCookie();
    clearPaymentCookie();

    const location = paymentCookie.returnUrl || returnUrl || '/';
    if (paymentCookie.useHardRedirect) {
      window.location = location;
    } else {
      history.navigate(location, { trigger: true, replace: true });
    }
  };
}

function onClose(routerReturnUrl) {
  return () => {
    history.navigate(routerReturnUrl || '/', { trigger: true });
  };
}

export function openPayment(returnUrl) {
  const user = Auth.getUser();

  TopUpActions.resetState();
  PaymentActions.fetchPaymentMethods(user);

  Analytics.track('Start Payment', { balance: user.get('balance') });

  return <TopUpContainer onClose={onClose(returnUrl)} />;
}

export function openOutOfBalance(returnUrl) {
  return (
    <OutOfBalanceDialogContainer onDialogClosed={onClose(returnUrl)} onClose={onClose(returnUrl)} />
  );
}

export function openPaymentResult(condition) {
  Analytics.track(`Payment Result:${condition}`, getPaymentCookie());

  return <PaymentResultDialogue condition={condition} onClose={onCloseSuccess()} />;
}

export function openPaymentRecurring(returnUrl) {
  const user = Auth.getUser();
  PaymentActions.setRecurringContract(user, true);
  Analytics.track('Enabled Recurring Contract');

  return <PaymentRecurringDialogue onClose={onClose(returnUrl)} />;
}
