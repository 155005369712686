import React from 'react';
import { string } from 'prop-types';

function Lock({ fill, ...props }) {
  return (
    <svg viewBox="0 0 32 40" {...props}>
      <path
        d="M12 25c0-2.21 1.79-4 4-4s4 1.79 4 4c0 1.867-1.279 3.435-3.008 3.876.005.04.008.082.008.124v3c0 .552-.448 1-1 1s-1-.448-1-1v-3c0-.042.003-.083.008-.124C13.278 28.435 12 26.866 12 25zM7 14V9c0-4.97 4.03-9 9-9s9 4.03 9 9v5h2.843c2.21 0 4 1.79 4 4 0 .051 0 .103-.003.154l-.692 18C31.065 38.3 29.3 40 27.15 40H4.849C2.7 40 .935 38.301.852 36.154l-.692-18c-.085-2.208 1.636-4.066 3.843-4.151.051-.002.103-.003.154-.003H7zm-2.843 2c-1.18.044-2.041.973-1.999 2.077l.693 18C2.89 37.15 3.774 38 4.849 38h22.302c1.075 0 1.957-.85 1.998-1.923l.693-18 .001-.077c0-1.105-.895-2-2-2H4.157zM23 14V9c0-3.866-3.134-7-7-7S9 5.134 9 9v5h14zm-7 13c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z"
        fill={fill}
      />
    </svg>
  );
}

Lock.propTypes = {
  fill: string,
};

Lock.defaultProps = {
  fill: 'currentColor',
};

export default Lock;
