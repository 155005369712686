// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".paymentDetails-3XyVk{display:flex;flex-direction:row;align-items:center;color:#948784;margin-top:12px}.icon-hjCC4{width:14px;flex-shrink:0;margin-top:-5px}.message-546aK{margin:0 0 0 10px;font-size:12px}\n", ""]);
// Exports
exports.locals = {
	"paymentDetails": "paymentDetails-3XyVk",
	"icon": "icon-hjCC4",
	"message": "message-546aK"
};
module.exports = exports;
