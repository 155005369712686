import React from 'react';
import PropTypes from 'prop-types';
import { translate, getCurrencyWord } from 'instances/i18n';
import Dialogue from 'components/dialogues/Dialogue';
import PaymentHeader from 'components/dialogues/Header';

function PaymentRecurringDialogue({ onClose }) {
  return (
    <Dialogue className="dialogue-payment-recurring" onClose={onClose}>
      <PaymentHeader />
      <div className="body">
        <h1 className="dialogue-payment-recurring-title">
          {translate('payment.recurring.hotlink.title')}
        </h1>
        <div
          className="text"
          dangerouslySetInnerHTML={{
            __html: translate('payment.recurring.hotlink.body', {
              currency: getCurrencyWord(),
            }),
          }}
        />
        <button type="button" className="btn btn-dismiss" onClick={onClose}>
          {translate('app.buttons.i_get_it')}
        </button>
      </div>
    </Dialogue>
  );
}

PaymentRecurringDialogue.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PaymentRecurringDialogue;
