/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import { bool, func } from 'prop-types';
import { FEATURE_CAPABILITY_MICROPAYMENTS_TOPUP, INTERNAL_LOCATION } from 'app-constants';
import Analytics from 'instances/analytics';
import OutOfBalanceDialog from 'modules/payment/components/OutOfBalanceDialog';
import { capabilityIsLocked } from 'selectors/capabilities';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { __ } from 'ramda';
import altConnect from 'higher-order-components/altConnect';

const topupIsLocked = capabilityIsLocked(__, FEATURE_CAPABILITY_MICROPAYMENTS_TOPUP);

const OutOfBalanceDialogContainer = ({ onDialogClosed, isTopUpDisabled }) => {
  useEffect(() => {
    Analytics.track('Insufficient Balance Alert Shown', {
      topup_disabled: isTopUpDisabled,
    });
  }, []);

  const onClose = () => {
    Analytics.track('Insufficient Balance Alert Dismissed', {
      internal_location: INTERNAL_LOCATION.ITEM,
    });

    onDialogClosed();
  };

  return <OutOfBalanceDialog onClose={onClose} isTopUpDisabled={isTopUpDisabled} />;
};

OutOfBalanceDialogContainer.propTypes = {
  onDialogClosed: func.isRequired,
  isTopUpDisabled: bool.isRequired,
};

function mapStateToProps({ featureCapabilitiesState }, props) {
  return {
    isTopUpDisabled: topupIsLocked(featureCapabilitiesState),
    ...props,
  };
}

mapStateToProps.stores = {
  FeatureCapabilitiesStore,
};

const enhance = altConnect(mapStateToProps);

export default enhance(OutOfBalanceDialogContainer);
