import React from 'react';
import { func, bool } from 'prop-types';
import { Dialog, Backdrop, ButtonGroup } from '@blendle/lego';
import PremiumDialogBody from 'components/dialogues/PremiumDialogBody';
import Link from 'components/Link';
import styledModule from 'helpers/styledModule';
import staticImageUrl from 'helpers/staticImageUrl';
import CSS from './style.scss';

const Tite = styledModule.h3(CSS.title);
const BodyText = styledModule.p(CSS.bodyText);

const deviceSrc1x = staticImageUrl('/img/illustrations/premium/treasure_chest.png?auto=format');
const deviceSrc2x = staticImageUrl('/img/illustrations/premium/treasure_chest@2x.png?auto=format');

const OutOfBalanceDialog = ({ isTopUpDisabled, onClose }) => (
  <Dialog
    onClose={onClose}
    closeButtonClassName={CSS.closeButton}
    data-testid="out-of-balance-dialog"
  >
    <PremiumDialogBody
      backdropColor={Backdrop.yellow()}
      backdropShapeColor={Backdrop.green()}
      backdropShapeClassName={CSS.backdropShape}
      illustrationSrcSet={`${deviceSrc1x}, ${deviceSrc2x} 2x`}
    >
      <Tite>Sorry, je hebt niet genoeg tegoed</Tite>
      {isTopUpDisabled ? null : (
        <React.Fragment>
          <BodyText>Waardeer je tegoed op om verder te lezen</BodyText>
          <ButtonGroup vertical>
            <Link className="btn" href="/payment">
              Opwaarderen
            </Link>
          </ButtonGroup>
        </React.Fragment>
      )}
    </PremiumDialogBody>
  </Dialog>
);

OutOfBalanceDialog.propTypes = {
  onClose: func.isRequired,
  isTopUpDisabled: bool.isRequired,
};

export default OutOfBalanceDialog;
