// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".backdropShape-3Tkx6{height:100px;width:200px;transform:rotate(45deg);right:-100px;top:20px}.title-2vGho{font-weight:600;margin-bottom:10px}.bodyText-32n1_{font-weight:500;line-height:1.3}.closeButton-zuw5x path{fill:#fff}\n", ""]);
// Exports
exports.locals = {
	"backdropShape": "backdropShape-3Tkx6",
	"title": "title-2vGho",
	"bodyText": "bodyText-32n1_",
	"closeButton": "closeButton-zuw5x"
};
module.exports = exports;
