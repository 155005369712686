// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dialogBody-1JnUC{padding:0;transform:translate(0);font-family:\"GT-Walsheim\",sans-serif}.dialogBody-1JnUC h1,.dialogBody-1JnUC h2,.dialogBody-1JnUC h3{font-family:\"GT-Walsheim\",sans-serif}.backdrop-3pc8u{position:relative;height:100px;width:100%}.illustrationWrapper-jdrUI{position:absolute;top:40px;width:100%;height:150px;display:flex;align-items:center;justify-content:center}.content-3RGQ5{padding:30px}.content-3RGQ5.illustrationPadding-3Darf{padding-top:100px}\n", ""]);
// Exports
exports.locals = {
	"dialogBody": "dialogBody-1JnUC",
	"backdrop": "backdrop-3pc8u",
	"illustrationWrapper": "illustrationWrapper-jdrUI",
	"content": "content-3RGQ5",
	"illustrationPadding": "illustrationPadding-3Darf"
};
module.exports = exports;
