/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { translate } from 'instances/i18n';
import formatCurrency from 'helpers/formatCurrency';
import classNames from 'classnames';
import SelectBank from 'modules/payment/components/SelectBank';
import SelectPaymentMethod from 'modules/payment/components/SelectPaymentMethod';
import SelectAmount from 'modules/payment/components/SelectAmount';
import SellingPointContainer from 'modules/payment/containers/SellingPointContainer';
import {
  RECURRING_MINIMAL_AMOUNT,
  FEATURE_CAPABILITY_MICROPAYMENTS_AUTO_TOPUP,
} from 'app-constants';
import { capabilityIsLocked } from 'selectors/capabilities';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { __ } from 'ramda';
import PaymentDetailsMessage from './PaymentDetailsMessage';

const autoTopupIsLocked = capabilityIsLocked(__, FEATURE_CAPABILITY_MICROPAYMENTS_AUTO_TOPUP);

const TopUpOptions = ({
  paymentMethods,
  selectedPaymentMethod,
  selectedAmount,
  selectedBank,
  amounts,
  transactionFees,
  onAmountChange,
  onPaymentMethodChange,
  onBankChange,
  onSubmitOptions,
  paymentURLLoading,
  recurring,
  onSetRecurringContract,
  recurringContracts,
}) => {
  const renderSelectBank = () => {
    const paymentMethod = paymentMethods.find(method => method.code === selectedPaymentMethod);

    return paymentMethod && paymentMethod.banks ? (
      <SelectBank
        onSelect={onBankChange}
        paymentMethod={paymentMethod}
        selectedBank={selectedBank}
      />
    ) : null;
  };

  const renderRecurring = () => {
    if (autoTopupIsLocked(FeatureCapabilitiesStore.getState())) {
      return null;
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div className="recurring-toggle-container" onClick={onSetRecurringContract}>
        <input type="checkbox" className="recurring-toggle-checkbox" checked={recurring} />
        <div>
          <strong>{translate('payment.recurring.option_title')}</strong>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="recurring-toggle-label">
            {translate('payment.recurring.option_body', {
              amount: formatCurrency(RECURRING_MINIMAL_AMOUNT),
            })}
          </label>
        </div>
      </div>
    );
  };

  const renderSubmitButton = () => {
    const transactionFee = transactionFees[selectedAmount] || 0;
    const amount = parseFloat(selectedAmount) + transactionFee;
    const paymentMethod =
      paymentMethods.find(method => method.code === selectedPaymentMethod) ||
      recurringContracts.find(contract => contract.id === selectedPaymentMethod);

    const enabled = paymentMethod && (!paymentMethod.banks || selectedBank !== '0');
    const className = classNames('btn-green', 'btn-fullwidth', 'btn-checkout');

    return (
      <Button
        disabled={!enabled}
        loading={paymentURLLoading}
        className={className}
        onClick={onSubmitOptions}
      >
        {translate('payment.buttons.submit', [formatCurrency(amount)])}
      </Button>
    );
  };

  const className = classNames('top-up-options', 'new-recurring-layout');

  return (
    <div className={className}>
      <SellingPointContainer />
      <div className="top-up-options-dropdowns">
        <SelectAmount
          amounts={amounts}
          selectedAmount={selectedAmount}
          transactionFees={transactionFees}
          onSelect={onAmountChange}
        />
        <SelectPaymentMethod
          recurringContracts={recurringContracts}
          paymentMethods={paymentMethods}
          selectedPaymentMethod={selectedPaymentMethod}
          onSelect={onPaymentMethodChange}
        />
        {renderSelectBank()}
      </div>
      <div className="top-up-options-actions">
        {renderRecurring()}
        {renderSubmitButton()}
      </div>
      <PaymentDetailsMessage />
    </div>
  );
};

TopUpOptions.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
  selectedPaymentMethod: PropTypes.string.isRequired,
  selectedAmount: PropTypes.number.isRequired,
  selectedBank: PropTypes.string,
  amounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  transactionFees: PropTypes.object.isRequired,
  onAmountChange: PropTypes.func.isRequired,
  onPaymentMethodChange: PropTypes.func.isRequired,
  onBankChange: PropTypes.func.isRequired,
  onSubmitOptions: PropTypes.func.isRequired,
  paymentURLLoading: PropTypes.bool.isRequired,
  recurring: PropTypes.bool.isRequired,
  onSetRecurringContract: PropTypes.func.isRequired,
  recurringContracts: PropTypes.array.isRequired,
};

export default TopUpOptions;
